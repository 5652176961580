import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

function Sidebar(props) {
  const [state, setState] = useState([]);
  const authData = useSelector((state) => state.authentication);
  // function toggleMenuState(menuState) {
  //   if (state[menuState]) {
  //     setState({ [menuState]: false });
  //   } else if (Object.keys(state).length === 0) {
  //     setState({ [menuState]: true });
  //   } else {
  //     Object.keys(state).forEach((i) => {
  //       setState({ [i]: false });
  //     });
  //     setState({ [menuState]: true });
  //   }
  // }

  // function componentDidUpdate(prevProps) {
  //   if (props.location !== prevProps.location) {
  //     onRouteChanged();
  //   }
  // }

  function onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(state).forEach((i) => {
      setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setState({ [obj.state]: true });
      }
    });
  }

  function isPathActive(path) {
    return props.location.pathname.startsWith(path);
  }

  useEffect(() => {
    onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  });

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
        <a className="sidebar-brand brand-logo" href="index.html">
          <img
            src={require("../../assets/images/goadventure-log.png")}
            alt="logo"
          />
        </a>
        <a className="sidebar-brand brand-logo-mini pt-3" href="index.html">
          <img
            src={require("../../assets/images/goadventure-log.png")}
            alt="logo"
          />
        </a>
      </div>
      <ul className="nav">
        <li className="nav-item nav-profile not-navigation-link">
          <div className="nav-link">
            <Dropdown>
              <Dropdown.Toggle className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                <div className="d-flex justify-content-between align-items-start">
                  <div className="profile-image">
                    <img
                      className="img-xs rounded-circle"
                      src={require("../../assets/images/faces-clipart/pic-4.png")}
                      alt="profile"
                    />
                    <div className="dot-indicator bg-success"></div>
                  </div>
                  <div className="text-wrapper">
                    <p className="profile-name">
                      {authData && authData.user ? authData.user.username : ""}
                    </p>
                    <p className="designation">
                      {" "}
                      {authData && authData.user ? authData.user.role : ""} User
                    </p>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                <Dropdown.Item
                  className="dropdown-item p-0 preview-item d-flex align-items-center"
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="d-flex">
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                      <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                    </div>
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                      <i className="mdi mdi-account-outline mr-0"></i>
                    </div>
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                      <i className="mdi mdi-alarm-check mr-0"></i>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center text-small"
                  onClick={(evt) => evt.preventDefault()}
                >
                  Manage Accounts
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center text-small"
                  onClick={(evt) => evt.preventDefault()}
                >
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center text-small"
                  onClick={(evt) => evt.preventDefault()}
                >
                  Check Inbox
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center text-small"
                  onClick={(evt) => evt.preventDefault()}
                >
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>

        <li
          className={
            isPathActive("/dashboard") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/dashboard">
            <i className="mdi mdi-television menu-icon"></i>
            <span className="menu-title">Dashboard</span>
          </Link>
        </li>

        {
          authData.user && ['admin', 'sales'].indexOf(authData.user.role) > -1 ? <li
            className={isPathActive("/_config_") ? "nav-item active" : "nav-item"}
          >
            <Link className="nav-link" to="/trip_report">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Trip Report</span>
            </Link>
          </li> : null
        }

{
          authData.user && ['admin', 'sales'].indexOf(authData.user.role) > -1 ? <li
            className={isPathActive("/_config_") ? "nav-item active" : "nav-item"}
          >
            <Link className="nav-link" to="/ongoing_trips">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">OnGoing Trips</span>
            </Link>
          </li> : null
        }

        {/* Main Start */}
        <li
          className={isPathActive("/packages") ? "nav-item active" : "nav-item"}
        >
          <Link className="nav-link" to="/packages">
            <i className="mdi mdi-television menu-icon"></i>
            <span className="menu-title">Packages</span>
          </Link>
        </li>
        {authData.user && authData.user.role === "admin" && (
          <li
            className={isPathActive("/blog") ? "nav-item active" : "nav-item"}
          >
            <Link className="nav-link" to="/blog">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Blog</span>
            </Link>
          </li>
        )}
        {authData.user &&
          (authData.user.role === "admin" ||
            authData.user.role === "sales") && (
            <li
              className={
                isPathActive("/bookings") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/bookings">
                <i className="mdi mdi-television menu-icon"></i>
                <span className="menu-title">Bookings</span>
              </Link>
            </li>
          )}
        <li
          className={
            isPathActive("/coupon-codes") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/coupon-codes">
            <i className="mdi mdi-television menu-icon"></i>
            <span className="menu-title">Coupon Codes</span>
          </Link>
        </li>
        {authData.user &&
          (authData.user.role === "admin" ||
            authData.user.role === "sales") && (
            <li
              className={
                isPathActive("/sales") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/sales">
                <i className="mdi mdi-television menu-icon"></i>
                <span className="menu-title">Sales Overview</span>
              </Link>
            </li>
          )}
        {authData.user && authData.user.role === "admin" && (
          <li
            className={
              isPathActive("/users-9") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/users-9">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Users</span>
            </Link>
          </li>
        )}
        <li
          className={isPathActive("/_config_") ? "nav-item active" : "nav-item"}
        >
          <Link className="nav-link" to="/_config_">
            <i className="mdi mdi-television menu-icon"></i>
            <span className="menu-title">Config</span>
          </Link>
        </li>
        {/* Main End */}
        {/* <li className={isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
          <div className={state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            <span className="menu-title">Basic UI Elements</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.basicUiMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link'} to="/basic-ui/buttons">Buttons</Link></li>
              <li className="nav-item"> <Link className={isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link'} to="/basic-ui/dropdowns">Dropdowns</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/form-elements') ? 'nav-item active' : 'nav-item'}>
          <div className={state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('formElementsMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            <span className="menu-title">Form Elements</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.formElementsMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link'} to="/form-elements/basic-elements">Basic Elements</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/tables') ? 'nav-item active' : 'nav-item'}>
          <div className={state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('tablesMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-table-large menu-icon"></i>
            <span className="menu-title">Tables</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.tablesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link'} to="/tables/basic-table">Basic Table</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/icons') ? 'nav-item active' : 'nav-item'}>
          <div className={state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('iconsMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-account-box-outline menu-icon"></i>
            <span className="menu-title">Icons</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.iconsMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link'} to="/icons/mdi">Material</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/charts') ? 'nav-item active' : 'nav-item'}>
          <div className={state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('chartsMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-chart-line menu-icon"></i>
            <span className="menu-title">Charts</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.chartsMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link'} to="/charts/chart-js">Chart Js</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/user-pages') ? 'nav-item active' : 'nav-item'}>
          <div className={state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('userPagesMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-lock-outline menu-icon"></i>
            <span className="menu-title">User Pages</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.userPagesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link'} to="/user-pages/login-1">Login</Link></li>
              <li className="nav-item"> <Link className={isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link'} to="/user-pages/register-1">Register</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/error-pages') ? 'nav-item active' : 'nav-item'}>
          <div className={state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('errorPagesMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-information-outline menu-icon"></i>
            <span className="menu-title">Error Pages</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.errorPagesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link'} to="/error-pages/error-404">404</Link></li>
              <li className="nav-item"> <Link className={isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link'} to="/error-pages/error-500">500</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="http://www.bootstrapdash.com/demo/star-admin-free/react/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
            <i className="mdi mdi-file-outline menu-icon"></i>
            <span className="menu-title">Documentation</span>
          </a>
        </li> */}
      </ul>
    </nav>
  );
}

export default withRouter(Sidebar);
