import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { bookingsService } from "../../_services/bookings.service";
const StayModal = ({
  show,
  setShow,
  accIndex,
  setAccIndex,
  Id
}) => {
  const [staybooking, setStayBooking] = useState({
    stayDetails: {
      noOfDays: "",
      checkIn_date: "",
      hotel_name: "",
      owner_name: "",
      manager_No: "",
      location: "",
      amount: "",
      meal_plan: "",
    },
    coTravellers: [],
  });
  useEffect(() => {
    if (Id) {
      getBookingDetails();
    } else {
      closeModal();
    }
  }, [Id]);

  const getBookingDetails = () => {
    bookingsService.getById(Id).then((resp) => {
      if (!resp.coTravellers) {
        resp["coTravellers"] = Array.from(new Array(resp?.noOfUsers - 1)).map(
          (e) => {
            return {};
          }
        );
      }
      setStayBooking(resp);
    });
  };

  const handleCustomerStayDetailsChange = (key, value) => {
    let stayDetails = { ...staybooking.stayDetails };
    stayDetails[key] = value;
    setStayBooking({ ...staybooking, stayDetails });
  };
  const handleCoTravellerData = (key, value, index) => {
    let Obj = Object.assign({}, staybooking);
    if (Number.isInteger(index)) {
      Obj["coTravellers"][index].stayDetails = {
        ...Obj["coTravellers"][index].stayDetails,
        [key]: value,
      };
    }
    setStayBooking(Obj);
  };
  const handleModalChanges = () => {
    bookingsService.update(staybooking).then((resp) => {
      console.log(resp);
      closeModal();
    });
  };

  const closeModal = () => {
    setShow({ ...show, showStayModal: false });
  };
  const renderCoTravellers = () => {
    return staybooking.coTravellers.map((e, i) => (
      <div className="accordion mb-1" id={`accordion${i}`} key={`cot-${i}`}>
        <div className="card">
          <div className="card-header" id={`heading${i}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-capitalize"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${i}`}
                aria-expanded="true"
                aria-controls={`collapse${i}`}
                onClick={() =>
                  accIndex.accIndexStay == i
                    ? setAccIndex({ ...accIndex, accIndexStay: -1 })
                    : setAccIndex({ ...accIndex, accIndexStay: i })
                }
              >
                {e.name || `Co-Traveller ${i + 1}`}{" "}
                {e.gender ? `(${e.gender})` : ""}
              </button>
            </h2>
          </div>

          <div
            id={`collapse${i}`}
            className={`collapse p-3 ${
              accIndex.accIndexStay == i ? "show" : ""
            }`}
            aria-labelledby={`heading${i}`}
            data-parent={`#accordion${i}`}
          >
            <div className="form-group">
              <label>Number of Days</label>
              <input
                className="form-control"
                placeholder="number of days"
                value={e.stayDetails?.noOfDays || ""}
                onChange={(event) =>
                  handleCoTravellerData("noOfDays", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Date Of Check In</label>
              <input
                className="form-control"
                placeholder="check in"
                type="date"
                value={e.stayDetails?.checkIn_date || ""}
                onChange={(event) =>
                  handleCoTravellerData("checkIn_date", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Hotel Name</label>
              <input
                className="form-control"
                placeholder="hotel name"
                value={e.stayDetails?.hotel_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("hotel_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Owner Name</label>
              <input
                className="form-control"
                placeholder="owner name"
                value={e.stayDetails?.owner_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("owner_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Manager Number</label>
              <input
                className="form-control"
                placeholder="manager number"
                value={e.stayDetails?.manager_No || ""}
                onChange={(event) =>
                  handleCoTravellerData("manager_No", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Location</label>
              <input
                className="form-control"
                placeholder="Location"
                value={e.stayDetails?.location || ""}
                onChange={(event) =>
                  handleCoTravellerData("location", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Amount</label>
              <input
                className="form-control"
                placeholder="amount"
                value={e.stayDetails?.amount || ""}
                onChange={(event) =>
                  handleCoTravellerData("amount", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Meal Plan</label>
              <input
                className="form-control"
                placeholder="Meal Plan"
                value={e.stayDetails?.meal_plan || ""}
                onChange={(event) =>
                  handleCoTravellerData("meal_plan", event.target.value, i)
                }
              />
            </div>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <Modal show={show.showStayModal} onHide={() => closeModal()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Update Stay Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Number of Days</label>
                <input
                  className="form-control"
                  placeholder="number of days"
                  value={staybooking.stayDetails?.noOfDays}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "noOfDays",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Date Of Check In</label>
                <input
                  className="form-control"
                  placeholder="check in"
                  type="date"
                  value={staybooking.stayDetails?.checkIn_date}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "checkIn_date",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Hotel Name</label>
                <input
                  className="form-control"
                  placeholder="hotel name"
                  value={staybooking.stayDetails?.hotel_name}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "hotel_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Owner Name</label>
                <input
                  className="form-control"
                  placeholder="owner name"
                  value={staybooking.stayDetails?.owner_name}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "owner_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Manager Number</label>
                <input
                  className="form-control"
                  placeholder="manager number"
                  value={staybooking.stayDetails?.manager_No}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "manager_No",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Location</label>
                <input
                  className="form-control"
                  placeholder="Location"
                  value={staybooking.stayDetails?.location}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "location",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Amount</label>
                <input
                  className="form-control"
                  placeholder="amount"
                  value={staybooking.stayDetails?.amount}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "amount",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Meal Plan</label>
                <input
                  className="form-control"
                  placeholder="Meal Plan"
                  value={staybooking.stayDetails?.meal_plan}
                  onChange={(event) =>
                    handleCustomerStayDetailsChange(
                      "meal_plan",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
          {staybooking.coTravellers.length > 0 && (
            <div>{renderCoTravellers()}</div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button
          className="btn btn-sm btn-primary"
          onClick={() => handleModalChanges()}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default StayModal;
