import { packageConstants } from "../_constants";

const initialState = { newPackage: {}, packages: [], activePackage: {} };

export function packages(state = initialState, action) {
  switch (action.type) {
    case packageConstants.NEW_PACKAGE_SUCCESS:
      return { ...state };
    case packageConstants.NEW_PACKAGE_FAILURE:
      return { ...state };
    case packageConstants.PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        packages: action.packages.map((_package) => {
          return markInvalidPackages(_package);
        }),
      };
    case packageConstants.GET_PACKAGE_SUCCESS:
      return {
        ...state,
        activePackage: action._package,
      };
    case packageConstants.PACKAGE_LIST_FAILURE:
    default:
      return { ...state };
  }
}

function markInvalidPackages(_package) {
  _package.isValid = true;

  _package.trips = _package.trips.filter((trip) => {
    return new Date(trip.startDate).getTime() > new Date().getTime();
  });

  if (_package.trips.length === 0) {
    _package.isValid = false;
    return _package;
  }

  for (var v = 0; v < _package.trips.length; v++) {
    const trip = _package.trips[v];
    const travelCostCenters = trip.costCenters.filter(
      (costCenter) => costCenter.costType === "TravelType"
    );
    let travelTypes = trip.travelTypes.sort();
    if (travelTypes.length !== travelCostCenters.length) {
      _package.isValid = false;
      break;
    }

    const cTravelTypes = travelCostCenters
      .map((costCenter) => {
        return costCenter.travelType.name;
      })
      .sort();

    if (travelTypes.join("-") !== cTravelTypes.join("-")) {
      _package.isValid = false;
      break;
    }
  }

  return _package;
}
