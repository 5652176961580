import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from "moment"
import _ from 'lodash'

import { tripsService } from "../../_services/trips.service";

const OnGoingTrips = ({ history }) => {

    const [list, setList] = useState([])
    const [maxDays, setMaxDays] = useState(15);


    useEffect(() => {
        getTrips()
    }, [maxDays])

    const getTrips = () => {
        let lte_date = maxDays * 24 * 60 * 60 * 1000;
        tripsService.filter({ endDate: { between: [Date.now(), Date.now() + lte_date] } })
            .then((resp) => {
                resp = resp.map((e) => {
                    e.noOfBookings = e.bookings.length;
                    e.noOfTravellers = _.sumBy(e.bookings, (e) => e.noOfUsers + 1)
                    e.totalAmount = _.sumBy(e.bookings, (e) => e.totalAmount)
                    e.amountReceived = _.sumBy(e.bookings, (e) => e.paidAmount)
                    e.amountPending = _.sumBy(e.bookings, (e) => e.totalAmount - e.paidAmount)
                    return e
                })
                setList(resp)
            })
    }

    const navigateToTripReport = (data) => {
        history.push(`/trip_report?package=${data.package.id}&trip=${data.id}`)
    }

    const renderActions = ({ data }) => {
        return <div className="font-weight-bolder">
            <i className="fa fa-eye mr-3 text-primary" onClick={() => navigateToTripReport(data)}></i>
        </div>
    }

    const dateERenderer = ({ data }) => {
        return moment(data.endDate).format('LL')
    }

    const dateSRenderer = ({ data }) => {
        return moment(data.startDate).format('LL')
    }

    const colDefs = [
        { field: 'name', title: 'Trip Name', resizable: true, sortable: true, width: 400 },
        { field: 'startDate', title: 'Start Date', cellRenderer: dateSRenderer, resizable: true, sortable: true },
        { field: 'endDate', title: 'End Date', cellRenderer: dateERenderer, resizable: true },
        { field: 'noOfBookings', title: '#Bookings', resizable: true },
        { field: 'noOfTravellers', title: '#Travellers', resizable: true },
        { field: 'amountReceived', title: 'Amount Received', resizable: true },
        { field: 'amountPending', title: 'Amount Pending', resizable: true },
        { field: 'totalAmount', title: 'Total Amount', resizable: true },
        { field: 'Actions', title: 'Actions', cellRenderer: renderActions }
    ]

    const gridOptions = {
        rowClassRules:{
            "bg-danger text-white": params => params.api.getValue("noOfBookings", params.node) == 0
        }
    }

    return <div>
        <div className="row page-title-header">
            <div className="col-12">
                <div className="page-header">
                    <h4 className="page-title">On Going Trips</h4>
                    <div>
                        <select className='form-control' value={maxDays} onChange={(event) => {
                            setMaxDays(event.target.value)
                        }}>
                            <option value={5}>5 Days</option>
                            <option value={10}>10 Days</option>
                            <option value={15}>15 Days</option>
                            <option value={30}>30 Days</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 grid-margin">
                <div className="ag-theme-alpine" style={{ height: '70vh', width: '100%' }}>
                    <AgGridReact
                        gridOptions={gridOptions}
                        pagination={true}
                        rowData={list}
                        columnDefs={colDefs}>
                    </AgGridReact>
                </div>
            </div>
        </div>
    </div>
}

export default OnGoingTrips