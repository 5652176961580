export const packageConstants = {
  NEW_PACKAGE: "NEW_PACKAGE",
  NEW_PACKAGE_SUCCESS: "NEW_PACKAGE_SUCCESS",
  NEW_PACKAGE_FAILURE: "NEW_PACKAGE_FAILURE",

  PACKAGE_LIST_REQUEST: "PACKAGE_LIST_REQUEST",
  PACKAGE_LIST_SUCCESS: "PACKAGE_LIST_SUCCESS",
  PACKAGE_LIST_FAILURE: "PACKAGE_LIST_FAILURE",

  PACKAGE_UPDATE_REQUEST: "PACKAGE_UPDATE_REQUEST",
  PACKAGE_UPDATE_SUCCESS: "PACKAGE_UPDATE_SUCCESS",
  PACKAGE_UPDATE_FAILURE: "PACKAGE_UPDATE_FAILURE",

  GET_PACKAGE_SUCCESS: "GET_PACKAGE_SUCCESS",

  PACKAGE_DELETE_REQUEST: "PACKAGE_DELETE_REQUEST",
  PACKAGE_DELETE_SUCCESS: "PACKAGE_DELETE_SUCCESS",
  PACKAGE_DELETE_FAILURE: "PACKAGE_DELETE_FAILURE",
};
