import { tripConstants } from "../_constants";
import { markInvalidTrip } from "../_helpers/util";

const initialState = { newTrip: {}, trips: [], activeTrip: {} };

export function trips(state = initialState, action) {
  switch (action.type) {
    case tripConstants.NEW_TRIP_SUCCESS:
      return { ...state };
    case tripConstants.NEW_TRIP_FAILURE:
      return { ...state };
    case tripConstants.TRIP_LIST_SUCCESS:
      return {
        ...state,
        trips: action.trips.map((trip) => markInvalidTrip(trip)),
      };
    case tripConstants.GET_TRIP_SUCCESS:
      return {
        ...state,
        activeTrip: action.trip,
      };
    case tripConstants.TRIP_LIST_FAILURE:
    default:
      return { ...state };
  }
}
