import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { users } from "./users.reducer";
import { alert } from "./alert.reducer";

import { packages } from "./packages.reducer";
import { shared } from "./shared.reducer";
import { trips } from "./trips.reducer";
import { costCenters } from "./costcenter.reducer";

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  packages,
  shared,
  trips,
  costCenters,
});

export default rootReducer;
