import { packageConstants } from "../_constants";
import { packagesService } from "../_services";
import { alertActions } from "./";

export const packagesActions = {
  add,
  list,
  update,
  getById,
  deleteById,
};

function add(newPackage) {
  return (dispatch) => {
    return packagesService.add(newPackage).then(
      (addedPackage) => {
        dispatch(success(addedPackage));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(obj) {
    return { type: packageConstants.NEW_PACKAGE_SUCCESS, obj };
  }
  function failure(obj) {
    return { type: packageConstants.NEW_PACKAGE_FAILURE, obj };
  }
}

function list() {
  return (dispatch) => {
    return packagesService.list().then(
      (packages) => dispatch(success(packages)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  // function request(packages) {
  //   return { type: packageConstants.PACKAGE_LIST_REQUEST, packages };
  // }
  function success(packages) {
    return { type: packageConstants.PACKAGE_LIST_SUCCESS, packages };
  }
  function failure(packages) {
    return { type: packageConstants.PACKAGE_LIST_FAILURE, packages };
  }
}

function getById(id) {
  return (dispatch) => {
    return packagesService.getById(id).then(
      (_package) => dispatch(success(_package)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(_package) {
    return { type: packageConstants.GET_PACKAGE_SUCCESS, _package };
  }
  function failure(_package) {
    return { type: packageConstants.PACKAGE_LIST_FAILURE, _package };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function update(updatePackage) {
  return (dispatch) => {
    return packagesService.update(updatePackage).then(
      (updatePackage) => dispatch(success(updatePackage)),
      (error) => dispatch(failure(updatePackage, error.toString()))
    );
  };

  // function request(obj) {
  //   return { type: packageConstants.PACKAGE_UPDATE_REQUEST, obj };
  // }
  function success(obj) {
    return { type: packageConstants.PACKAGE_UPDATE_SUCCESS, obj };
  }
  function failure(obj) {
    return { type: packageConstants.PACKAGE_UPDATE_FAILURE, obj };
  }
}

function deleteById(id) {
  return (dispatch) => {
    return packagesService.deleteById(id).then(
      (_package) => dispatch(success(_package)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(_package) {
    return { type: packageConstants.PACKAGE_DELETE_SUCCESS, _package };
  }
  function failure(_package) {
    return { type: packageConstants.PACKAGE_DELETE_FAILURE, _package };
  }
}
