import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { bookingsService } from "../../_services/bookings.service";
const TrainModal = ({
  show,
  setShow,
  accIndex,
  setAccIndex,
  Id
}) => {
  const [trainbooking, setTrainBooking] = useState({
    trainDetails: {
      travel_name: "",
      travel_date: "",
      train_name: "",
      train_source: "",
      train_dest: "",
      ticket_amt: "",
      train_coachNo: "",
      train_berthNo: "",
    },
    coTravellers: [],
  });
  useEffect(() => {
    if (Id) {
      getBookingDetails()
    } else {
      closeModal()
    }
  }, [Id]);

  const getBookingDetails = () => {
    bookingsService.getById(Id).then((resp) => {
      if (!resp.coTravellers) {
        resp["coTravellers"] = Array.from(new Array(resp?.noOfUsers - 1)).map(
          (e) => {
            return {};
          }
        );
      }
      setTrainBooking(resp);
    });
  };

  const handleModalChanges = () => {
      bookingsService.update(trainbooking).then((resp) => {
        console.log(resp);
        closeModal();
      });
  }

  const handleCustomerTrainDetailsChange = (key, value) => {
    let trainDetails = { ...trainbooking.trainDetails };
    trainDetails[key] = value;
    setTrainBooking({ ...trainbooking, trainDetails });
  };

  const handleCoTravellerData = (key, value, index) => {
    let Obj = Object.assign({}, trainbooking);
    if (Number.isInteger(index)) {
      Obj["coTravellers"][index].trainDetails = {
        ...Obj["coTravellers"][index].trainDetails,
        [key]: value,
      };
    }
    setTrainBooking(Obj);
  };

  const closeModal = () => {
    setShow({ ...show, showTrainModal: false });
  };


  const renderCoTravellers = () => {
    return trainbooking.coTravellers.map((e, i) => (
      <div className="accordion mb-1" id={`accordion${i}`} key={`cot-${i}`}>
        <div className="card">
          <div className="card-header" id={`heading${i}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-capitalize"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${i}`}
                aria-expanded="true"
                aria-controls={`collapse${i}`}
                onClick={() =>
                  accIndex.accIndexTrain == i
                    ? setAccIndex({ ...accIndex, accIndexTrain: -1 })
                    : setAccIndex({ ...accIndex, accIndexTrain: i })
                }
              >
                {e.name || `Co-Traveller ${i + 1}`}{" "}
                {e.gender ? `(${e.gender})` : ""}
              </button>
            </h2>
          </div>

          <div
            id={`collapse${i}`}
            className={`collapse p-3 ${accIndex.accIndexTrain == i ? "show" : ""}`}
            aria-labelledby={`heading${i}`}
            data-parent={`#accordion${i}`}
          >
            <div className="form-group">
              <label>Travel Name</label>
              <input
                className="form-control"
                placeholder="travel name"
                value={e.trainDetails?.travel_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("travel_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Date Of Travel</label>
              <input
                className="form-control"
                placeholder="travel date"
                type="date"
                value={e.trainDetails?.travel_date || ""}
                onChange={(event) =>
                  handleCoTravellerData("travel_date", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Train Name</label>
              <input
                className="form-control"
                placeholder="train name"
                value={e.trainDetails?.train_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Source</label>
              <input
                className="form-control"
                placeholder="Source"
                value={e.trainDetails?.train_source || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_source", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Destination</label>
              <input
                className="form-control"
                placeholder="Destination"
                value={e.trainDetails?.train_dest || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_dest", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Ticket Amount</label>
              <input
                className="form-control"
                placeholder="Ticket Amount"
                value={e.trainDetails?.ticket_amt || ""}
                onChange={(event) =>
                  handleCoTravellerData("ticket_amt", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Coach No</label>
              <input
                className="form-control"
                placeholder="Coach No"
                value={e.trainDetails?.train_coachNo || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_coachNo", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Berth No</label>
              <input
                className="form-control"
                placeholder="Berth No"
                value={e.trainDetails?.train_berthNo || ""}
                onChange={(event) =>
                  handleCoTravellerData("train_berthNo", event.target.value, i)
                }
              />
            </div>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <Modal show={show.showTrainModal} onHide={() => closeModal()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Update Train Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Travel Name</label>
                <input
                  className="form-control"
                  placeholder="travel name"
                  value={trainbooking.trainDetails?.travel_name}
                  onChange={(event) =>
                    handleCustomerTrainDetailsChange(
                      "travel_name",
                      event.target.value
                    )
                  }
                />
        
              </div>
              <div className="form-group">
                <label>Date Of Travel</label>
                <input
                  className="form-control"
                  placeholder="travel date"
                  type="date"
                  value={trainbooking.trainDetails?.travel_date}
                  onChange={(event) =>
                    handleCustomerTrainDetailsChange(
                      "travel_date",
                      event.target.value
                    )
                  }
                />
       
              </div>
              <div className="form-group">
                <label>Train Name</label>
                <input
                  className="form-control"
                  placeholder="train name"
                  value={trainbooking.trainDetails?.train_name}
                  onChange={(event) =>
                    handleCustomerTrainDetailsChange(
                      "train_name",
                      event.target.value
                    )
                  }
                />
        
              </div>
              <div className="form-group">
                <label>Source</label>
                <input
                  className="form-control"
                  placeholder="Source"
                  value={trainbooking.trainDetails?.train_source}
                  onChange={(event) =>
                    handleCustomerTrainDetailsChange(
                      "train_source",
                      event.target.value
                    )
                  }
                />
        
              </div>
              <div className="form-group">
                <label>Destination</label>
                <input
                  className="form-control"
                  placeholder="Destination"
                  value={trainbooking.trainDetails?.train_dest}
                  onChange={(event) =>
                    handleCustomerTrainDetailsChange(
                      "train_dest",
                      event.target.value
                    )
                  }
                />
         
              </div>
              <div className="form-group">
                <label>Ticket Amount</label>
                <input
                  className="form-control"
                  placeholder="Ticket Amount"
                  value={trainbooking.trainDetails?.ticket_amt}
                  onChange={(event) =>
                    handleCustomerTrainDetailsChange(
                      "ticket_amt",
                      event.target.value
                    )
                  }
                />
        
              </div>
              <div className="form-group">
                <label>Coach No</label>
                <input
                  className="form-control"
                  placeholder="Coach No"
                  value={trainbooking.trainDetails?.train_coachNo}
                  onChange={(event) =>
                    handleCustomerTrainDetailsChange(
                      "train_coachNo",
                      event.target.value
                    )
                  }
                />
       
              </div>
              <div className="form-group">
                <label>Berth No</label>
                <input
                  className="form-control"
                  placeholder="Berth No"
                  value={trainbooking.trainDetails?.train_berthNo}
                  onChange={(event) =>
                    handleCustomerTrainDetailsChange(
                      "train_berthNo",
                      event.target.value
                    )
                  }
                />
                {/* {dataValidations.current.message(
                  "train_berthNo",
                  trainbooking.trainDetails?.train_berthNo,
                  "required"
                )} */}
              </div>
            </div>
          </div>
          {trainbooking.coTravellers.length > 0 && (
            <div>{renderCoTravellers()}</div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-sm btn-danger"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button
          className="btn btn-sm btn-primary"
          onClick={() => handleModalChanges()}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TrainModal;
