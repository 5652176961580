export const costCenterConstants = {
  NEW_COSTCENTER: "NEW_COSTCENTER",
  NEW_COSTCENTER_SUCCESS: "NEW_COSTCENTER_SUCCESS",
  NEW_COSTCENTER_FAILURE: "NEW_COSTCENTER_FAILURE",

  COSTCENTER_LIST_REQUEST: "COSTCENTER_LIST_REQUEST",
  COSTCENTER_LIST_SUCCESS: "COSTCENTER_LIST_SUCCESS",
  COSTCENTER_LIST_FAILURE: "COSTCENTER_LIST_FAILURE",

  GET_COSTCENTER_SUCCESS: "GET_COSTCENTER_SUCCESS",
  GET_COSTCENTER_FAILURE: "GET_COSTCENTER_FAILURE",

  COSTCENTER_UPDATE_REQUEST: "COSTCENTER_UPDATE_REQUEST",
  COSTCENTER_UPDATE_SUCCESS: "COSTCENTER_UPDATE_SUCCESS",
  COSTCENTER_UPDATE_FAILURE: "COSTCENTER_UPDATE_FAILURE",

  COSTCENTER_DELETE_FAILURE: "COSTCENTER_DELETE_FAILURE",
};
