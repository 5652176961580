import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { bookingsService } from "../../_services/bookings.service";
const VehicleModal = ({
  show,
  setShow,
  accIndex,
  setAccIndex,
  Id
}) => {
  const [vehiclebooking, setVehicleBooking] = useState({
    vehicleDetails: {
      travel_name: "",
      owner_name: "",
      vehicle_type: "",
      vehicle_No: "",
      duration: "",
      amount: "",
      driver_name: "",
      driver_No: "",
    },
    coTravellers: [],
  });
  useEffect(() => {
    if (Id) {
      getBookingDetails();
    } else {
      closeModal();
    }
  }, [Id]);

  const getBookingDetails = () => {
    bookingsService.getById(Id).then((resp) => {
      if (!resp.coTravellers) {
        resp["coTravellers"] = Array.from(new Array(resp?.noOfUsers - 1)).map(
          (e) => {
            return {};
          }
        );
      }
      setVehicleBooking(resp);
    });
  };

  const handleCustomerVehicleDetailsChange = (key, value) => {
    let vehicleDetails = { ...vehiclebooking.vehicleDetails };
    vehicleDetails[key] = value;
    setVehicleBooking({ ...vehiclebooking, vehicleDetails });
  };
  const handleCoTravellerData = (key, value, index) => {
    let Obj = Object.assign({}, vehiclebooking);
    if (Number.isInteger(index)) {
      Obj["coTravellers"][index].vehicleDetails = {
        ...Obj["coTravellers"][index].vehicleDetails,
        [key]: value,
      };
    }
    setVehicleBooking(Obj);
  };
  const handleModalChanges = () => {
    bookingsService.update(vehiclebooking).then((resp) => {
      console.log(resp);
      closeModal();
    });
  };
  const closeModal = () => {
    setShow({ ...show, showVehicleModal: false });
  };

  const renderCoTravellers = () => {
    return vehiclebooking.coTravellers.map((e, i) => (
      <div className="accordion mb-1" id={`accordion${i}`} key={`cot-${i}`}>
        <div className="card">
          <div className="card-header" id={`heading${i}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-capitalize"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${i}`}
                aria-expanded="true"
                aria-controls={`collapse${i}`}
                onClick={() =>
                  accIndex.accIndexVehicle == i
                    ? setAccIndex({ ...accIndex, accIndexVehicle: -1 })
                    : setAccIndex({ ...accIndex, accIndexVehicle: i })
                }
              >
                {e.name || `Co-Traveller ${i + 1}`}{" "}
                {e.gender ? `(${e.gender})` : ""}
              </button>
            </h2>
          </div>

          <div
            id={`collapse${i}`}
            className={`collapse p-3 ${
              accIndex.accIndexVehicle == i ? "show" : ""
            }`}
            aria-labelledby={`heading${i}`}
            data-parent={`#accordion${i}`}
          >
            <div className="form-group">
              <label>Travel Name</label>
              <input
                className="form-control"
                placeholder="travel name"
                value={e.vehicleDetails?.travel_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("travel_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Owner Name</label>
              <input
                className="form-control"
                placeholder="owner name"
                value={e.vehicleDetails?.owner_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("owner_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Vehicle Type</label>
              <input
                className="form-control"
                placeholder="vehicle type"
                value={e.vehicleDetails?.vehicle_type || ""}
                onChange={(event) =>
                  handleCoTravellerData("vehicle_type", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Vehicle Number</label>
              <input
                className="form-control"
                placeholder="Vehicle Number"
                value={e.vehicleDetails?.vehicle_No || ""}
                onChange={(event) =>
                  handleCoTravellerData("vehicle_No", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Duration</label>
              <input
                className="form-control"
                placeholder="Duration"
                value={e.vehicleDetails?.duration || ""}
                onChange={(event) =>
                  handleCoTravellerData("duration", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Amount</label>
              <input
                className="form-control"
                placeholder="Amount"
                value={e.vehicleDetails?.amount || ""}
                onChange={(event) =>
                  handleCoTravellerData("amount", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Driver Name</label>
              <input
                className="form-control"
                placeholder="Driver Name"
                value={e.vehicleDetails?.driver_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("driver_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Driver Number</label>
              <input
                className="form-control"
                placeholder="Driver number"
                value={e.vehicleDetails?.driver_No || ""}
                onChange={(event) =>
                  handleCoTravellerData("driver_No", event.target.value, i)
                }
              />
            </div>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <Modal show={show.showVehicleModal} onHide={() => closeModal()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Update Vehicle Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Travel Name</label>
                <input
                  className="form-control"
                  placeholder="travel name"
                  value={vehiclebooking.vehicleDetails?.travel_name}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "travel_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Owner Name</label>
                <input
                  className="form-control"
                  placeholder="owner name"
                  value={vehiclebooking.vehicleDetails?.owner_name}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "owner_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Vehicle Type</label>
                <input
                  className="form-control"
                  placeholder="vehicle type"
                  value={vehiclebooking.vehicleDetails?.vehicle_type}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "vehicle_type",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Vehicle Number</label>
                <input
                  className="form-control"
                  placeholder="Vehicle Number"
                  value={vehiclebooking.vehicleDetails?.vehicle_No}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "vehicle_No",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Duration</label>
                <input
                  className="form-control"
                  placeholder="Duration"
                  value={vehiclebooking.vehicleDetails?.duration}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "duration",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Amount</label>
                <input
                  className="form-control"
                  placeholder="Amount"
                  value={vehiclebooking.vehicleDetails?.amount}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "amount",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Driver Name</label>
                <input
                  className="form-control"
                  placeholder="Driver Name"
                  value={vehiclebooking.vehicleDetails?.driver_name}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "driver_name",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Driver Number</label>
                <input
                  className="form-control"
                  placeholder="Driver number"
                  value={vehiclebooking.vehicleDetails?.driver_No}
                  onChange={(event) =>
                    handleCustomerVehicleDetailsChange(
                      "driver_No",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
          {vehiclebooking.coTravellers.length > 0 && (
            <div>{renderCoTravellers()}</div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button className="btn btn-sm btn-primary" onClick={handleModalChanges}>
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default VehicleModal;
