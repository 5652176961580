import React, {useState, useRef } from 'react';
import {Modal, Form } from 'react-bootstrap'
import SimpleReactValidator from 'simple-react-validator';

import { bookingsService } from '../../_services/bookings.service';

const UpdatePayment = ({ clear, data, getBookings}) => {

    const [showModal, toggleModal] = useState(true)

    const validator = useRef(new SimpleReactValidator({
        element: (msg) => <small className="text-danger">{msg}</small>
    }))
    const [update, forceUpdate] = useState(false)

    const [form, setForm] = useState({})

    const closeModal = () => {
        clear(false)
    }

    const handleChange = (event, key) => {
        setForm({
            ...form,
            [key]: event.target.value
        })
    }

    const handleSave = () => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            return forceUpdate(!update)
        }

        if (!data.paymentHistory) {
            data['paymentHistory'] = []
        }

        data.paymentHistory.push({ ...form })

        data.dueAmount = data.dueAmount - Number(form.amount);
        data.paidAmount = data.paidAmount + Number(form.amount);

        data.fullPaymentCompleted = data.dueAmount < 1

        bookingsService.update(data)
            .then((resp) => {
                clear();
                getBookings();
            })
            .catch((error)=>{
                console.log(error)
            })
    }

    const today = () => {
        return new Date()
    }

    return <>
        <Modal show={showModal} onHide={closeModal} size="lg"
            aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Payment Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className='row'>
                        <div className='col'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Payed By</th>
                                        <th>Payment Mode</th>
                                        <th>Amount</th>
                                        <th>Transaction ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.paymentHistory ? data.paymentHistory.map((e) => <tr>
                                            <td>{e.date}</td>
                                            <td>{e.payed_by}</td>
                                            <td>{e.payment_mode}</td>
                                            <td>{e.amount}</td>
                                            <td>{e.transaction_id}</td>
                                        </tr>) : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <label>Payed By</label>
                                    <input className='form-control' placeholder='Payed By' value={form.payed_by || ''} onChange={(event) => handleChange(event, 'payed_by')} />
                                    {
                                        validator.current.message('payed by', form.payed_by, 'required')
                                    }
                                </div>
                                <div className='col-sm-12'>
                                    <label>Payment Mode</label>
                                    <select className='form-control' placeholder='Payment Mode' value={form.payment_mode || ''} onChange={(event) => handleChange(event, 'payment_mode')}>
                                        <option value="" disabled selected>Select Payment Mode</option>
                                        <option value="cashfree">Payment Gateway</option>
                                        <option value="ra">Reventh Account</option>
                                        <option value="ga">Goadventure Account</option>
                                    </select>
                                    {
                                        validator.current.message('payment mode', form.payment_mode, 'required')
                                    }
                                </div>

                                <div className='col-sm-12'>
                                    <label>Amount</label>
                                    <input className='form-control' placeholder='Amount' value={form.amount || ''} onChange={(event) => handleChange(event, 'amount')} />
                                    {
                                        validator.current.message('amount', form.amount, `required|numeric|min:0,num|max:${data.dueAmount},num`)
                                    }
                                </div>

                                <div className='col-sm-12'>
                                    <label>Transaction ID</label>
                                    <input className='form-control' placeholder='Transaction ID' value={form.transaction_id || ''} onChange={(event) => handleChange(event, 'transaction_id')} />
                                    {
                                        validator.current.message('transaction id', form.transaction_id, 'required')
                                    }
                                </div>

                                <div className='col-sm-12'>
                                    <label>Date Of Payment</label>
                                    <input className='form-control' type="date" placeholder='Payment Mode' value={form.date || ''} onChange={(event) => handleChange(event, 'date')} />
                                    {
                                        validator.current.message('payment date', form.date, 'required')
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-sm btn-danger' onClick={() => closeModal()}>
                    Close
                </button>
                <button className='btn btn-sm btn-primary' onClick={() => handleSave()}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    </>
}

export default UpdatePayment