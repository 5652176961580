import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { bookingsService } from "../../_services/bookings.service";
const BusModal = ({
  show,
  setShow,
  accIndex,
  setAccIndex,
  Id
}) => {
  const [busbooking, setBusBooking] = useState({
    busDetails: {
      travel_name: "",
      travel_date: "",
      bus_type: "",
      driver_No: "",
      dep_time: "",
      amount: "",
      pick_point: "",
      bus_source: "",
      bus_dest: "",
    },
    coTravellers: [],
  });
  const [error, setErrors] = useState(false);
  useEffect(() => {
    if (Id) {
      getBookingDetails();
    } else {
      closeModal();
    }
  }, [Id]);

  const getBookingDetails = () => {
    bookingsService.getById(Id).then((resp) => {
      if (!resp.coTravellers) {
        resp["coTravellers"] = Array.from(new Array(resp?.noOfUsers - 1)).map(
          (e) => {
            return {};
          }
        );
      }
      setBusBooking(resp);
    });
  };
  const handleModalChanges = () => {
    bookingsService.update(busbooking).then((resp) => {
      console.log(resp);
      closeModal();
    });
  };
  const handleCustomerBusDetailsChange = (key, value) => {
    let busDetails = { ...busbooking.busDetails };
    busDetails[key] = value;
    setBusBooking({ ...busbooking, busDetails });
  };
  const handleCoTravellerData = (key, value, index) => {
    let Obj = Object.assign({}, busbooking);
    if (Number.isInteger(index)) {
      Obj["coTravellers"][index].busDetails = {
        ...Obj["coTravellers"][index].busDetails,
        [key]: value,
      };
    }
    setBusBooking(Obj);
  };

  const closeModal = () => {
    setShow({ ...show, showBusModal: false });
  };
  const openModal = () => {
    setShow({ ...show, showBusModal: true });
  };
  const renderCoTravellers = () => {
    return busbooking.coTravellers.map((e, i) => (
      <div className="accordion mb-1" id={`accordion${i}`} key={`cot-${i}`}>
        <div className="card">
          <div className="card-header" id={`heading${i}`}>
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-capitalize"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${i}`}
                aria-expanded="true"
                aria-controls={`collapse${i}`}
                onClick={() =>
                  accIndex.accIndexBus == i
                    ? setAccIndex({ ...accIndex, accIndexBus: -1 })
                    : setAccIndex({ ...accIndex, accIndexBus: i })
                }
              >
                {e.name || `Co-Traveller ${i + 1}`}{" "}
                {e.gender ? `(${e.gender})` : ""}
              </button>
            </h2>
          </div>

          <div
            id={`collapse${i}`}
            className={`collapse p-3 ${
              accIndex.accIndexBus == i ? "show" : ""
            }`}
            aria-labelledby={`heading${i}`}
            data-parent={`#accordion${i}`}
          >
            <div className="form-group">
              <label>Travel Name</label>
              <input
                className="form-control"
                placeholder="travel name"
                value={e.busDetails?.travel_name || ""}
                onChange={(event) =>
                  handleCoTravellerData("travel_name", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Date Of Travel</label>
              <input
                className="form-control"
                placeholder="travel name"
                type="date"
                value={e.busDetails?.travel_date || ""}
                onChange={(event) =>
                  handleCoTravellerData("travel_date", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Bus Type</label>
              <input
                className="form-control"
                placeholder="bus type"
                value={e.busDetails?.bus_type || ""}
                onChange={(event) =>
                  handleCoTravellerData("bus_type", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Driver Number</label>
              <input
                className="form-control"
                placeholder="Driver Number"
                value={e.busDetails?.driver_No || ""}
                onChange={(event) =>
                  handleCoTravellerData("driver_No", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Departure Time</label>
              <input
                className="form-control"
                placeholder="Departure time"
                value={e.busDetails?.dep_time || ""}
                onChange={(event) =>
                  handleCoTravellerData("dep_time", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Bus Fare</label>
              <input
                className="form-control"
                placeholder="Bus Fare"
                value={e.busDetails?.amount || ""}
                onChange={(event) =>
                  handleCoTravellerData("amount", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Pickup point</label>
              <input
                className="form-control"
                placeholder="pickup point"
                value={e.busDetails?.pick_point || ""}
                onChange={(event) =>
                  handleCoTravellerData("pick_point", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Source</label>
              <input
                className="form-control"
                placeholder="Source"
                value={e.busDetails?.bus_source || ""}
                onChange={(event) =>
                  handleCoTravellerData("bus_source", event.target.value, i)
                }
              />
            </div>
            <div className="form-group">
              <label>Destination</label>
              <input
                className="form-control"
                placeholder="Destination"
                value={e.busDetails?.bus_dest || ""}
                onChange={(event) =>
                  handleCoTravellerData("bus_dest", event.target.value, i)
                }
              />
            </div>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <Modal show={show.showBusModal} onHide={() => closeModal()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Update Bus Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Travel Name</label>
                <input
                  className="form-control"
                  placeholder="travel name"
                  value={busbooking.busDetails?.travel_name}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "travel_name",
                      event.target.value
                    )
                  }
                />
                {error && busbooking.busDetails.travel_name === "" && (
                  <h4 className="text-danger">**This field is required</h4>
                )}
              </div>
              <div className="form-group">
                <label>Date Of Travel</label>
                <input
                  className="form-control"
                  placeholder="travel name"
                  type="date"
                  value={busbooking.busDetails?.travel_date}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "travel_date",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Bus Type</label>
                <input
                  className="form-control"
                  placeholder="bus type"
                  value={busbooking.busDetails?.bus_type}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "bus_type",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Driver Number</label>
                <input
                  className="form-control"
                  placeholder="Driver Number"
                  value={busbooking.busDetails?.driver_No}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "driver_No",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Departure Time</label>
                <input
                  className="form-control"
                  placeholder="Departure time"
                  value={busbooking.busDetails?.dep_time}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "dep_time",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Bus Fare</label>
                <input
                  className="form-control"
                  placeholder="Bus Fare"
                  value={busbooking.busDetails?.amount}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange("amount", event.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <label>Pickup point</label>
                <input
                  className="form-control"
                  placeholder="pickup point"
                  value={busbooking.busDetails?.pick_point}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "pick_point",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Source</label>
                <input
                  className="form-control"
                  placeholder="Source"
                  value={busbooking.busDetails?.bus_source}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "bus_source",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Destination</label>
                <input
                  className="form-control"
                  placeholder="Destination"
                  value={busbooking.busDetails?.bus_dest}
                  onChange={(event) =>
                    handleCustomerBusDetailsChange(
                      "bus_dest",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
          {busbooking.coTravellers.length > 0 && (
            <div>{renderCoTravellers()}</div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => closeModal()}
        >
          Close
        </button>
        <button
          className="btn btn-sm btn-primary"
          onClick={() => handleModalChanges()}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BusModal;
