import config from "./config.json";
import { authHeader } from "../_helpers";
import { userActions } from "../_actions";

config.apiUrl = process.env.REACT_APP_API_URL

export const bookingsService = {
  add,
  list,
  update,
  getById,
  deleteById,
  getOrderById,
  generateSendPaymentLink,
};

function add(newBooking) {
  newBooking.secret = "a0c5adaf-1c50-4e77-b159-db9f5551418a";
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(newBooking),
  };

  return fetch(`${config.apiUrl}/bookings/new`, requestOptions)
    .then(handleResponse)
    .then((newBooking) => {
      return newBooking;
    });
}

function getOrderById() {
  const queryOptions = {};
  queryOptions.secret = "a0c5adaf-1c50-4e77-b159-db9f5551418a";
  queryOptions.type = "orderId";
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(queryOptions),
  };

  return fetch(`${config.apiUrl}/sequence-gen`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.value;
    });
}

function list(options) {
  options = options || {};
  options = Object.assign({ limit: 1000000, skip: 0 }, options);
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/bookings?filter=${JSON.stringify(options)}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id, filter = {}) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/bookings/${id}?filter=${JSON.stringify(filter)}`,
    requestOptions
  ).then(handleResponse);
}

function update(updateBooking) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader()},
    body: JSON.stringify(updateBooking),
  };

  return fetch(
    `${config.apiUrl}/bookings/${updateBooking.id}`,
    requestOptions
  ).then(handleResponse);
}

function deleteById(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };

  return fetch(`${config.apiUrl}/bookings/${id}`, requestOptions).then(
    handleResponse
  );
}

function generateSendPaymentLink(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${config.apiUrl}/bookings/generate-send-payment-link`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userActions.logout();
        // location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
