import config from "./config.json";
import { authHeader } from "../_helpers";

config.apiUrl = process.env.REACT_APP_API_URL

export const configService = {
  add,
  getAll,
  getById,
  update,
  deleteById: _delete,
  getCoverPhoto,
  getTravelTypes,
  deleteTravelType,
  updateTravelType,
  addTravelType
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/__configs`, requestOptions).then(
    handleResponse
  );
}

function getCoverPhoto() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/__configs?filter=${JSON.stringify({
      where: { _type: "COVER_PHOTOS" },
    })}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/__configs/${id}`, requestOptions).then(
    handleResponse
  );
}

function add(_config) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(_config),
  };

  return fetch(`${config.apiUrl}/__configs`, requestOptions).then(
    handleResponse
  );
}

function update(_config) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(_config),
  };

  return fetch(`${config.apiUrl}/__configs/${_config.id}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/__configs/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem("user");
        // location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function getTravelTypes(access_token){
  return fetch(`${config.apiUrl}/travel-types?access_token=${access_token}`).then(
    handleResponse
  );
}

function deleteTravelType(id, access_token){
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/travel-types/${id}?access_token=${access_token}`, requestOptions).then(
    handleResponse
  );
}

function updateTravelType(id, access_token, data){
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: authHeader()
  };
  return fetch(`${config.apiUrl}/travel-types/${id}?access_token=${access_token}`, requestOptions).then(
    handleResponse
  );
}

function addTravelType(access_token, data){
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: authHeader()
  };
  return fetch(`${config.apiUrl}/travel-types/?access_token=${access_token}`, requestOptions).then(
    handleResponse
  );
}
