import config from "./config.json";
import { authHeader } from "../_helpers";
import { userActions } from "../_actions";

config.apiUrl = process.env.REACT_APP_API_URL

export const tripsService = {
  add,
  list,
  update,
  getById,
  deleteById,
  replicate,
  filter
};

function add(newTrip) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(newTrip),
  };

  return fetch(`${config.apiUrl}/trips`, requestOptions)
    .then(handleResponse)
    .then((newTrip) => {
      return newTrip;
    });
}

function list(packageId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/trips?filter=${JSON.stringify({
      where: { packageId },
      include: [
        {
          relation: "trekLeader"
        },
        {
          relation: "package"
        },
        {
          relation: 'costCenters',
          scope: ["travelType"]
        },
        {
          relation: 'bookings',
          scope: {
            where: {
              status: 'SUCCESS'
            }
          }
        }
      ],
    })}`,
    requestOptions
  ).then(handleResponse);
}

function filter(where) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/trips?filter=${JSON.stringify({
      where,
      include: [
        {
          relation: "trekLeader"
        },
        {
          relation: "package"
        },
        {
          relation: 'costCenters',
          scope: ["travelType"]
        },
        {
          relation: 'bookings',
          scope: {
            where: {
              status: 'SUCCESS'
            }
          }
        }
      ],
      order: 'endDate ASC'
    })}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/trips/${id}?filter=${JSON.stringify({
      include: ["trekLeader", "package"],
    })}`,
    requestOptions
  ).then(handleResponse);
}

function update(updateTrip) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(updateTrip),
  };

  return fetch(`${config.apiUrl}/trips/${updateTrip.id}`, requestOptions).then(
    handleResponse
  );
}

function deleteById(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };

  return fetch(`${config.apiUrl}/trips/${id}`, requestOptions).then(
    handleResponse
  );
}

function replicate(tripId) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ tripId }),
  };

  return fetch(`${config.apiUrl}/trips/replicate`, requestOptions)
    .then(handleResponse)
    .then((newTrip) => {
      return newTrip;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userActions.logout();
        // location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
