import config from "./config.json";
import { authHeader } from "../_helpers";
import { userActions } from "../_actions";
config.apiUrl = process.env.REACT_APP_API_URL

export const costCentersService = {
  add,
  list,
  update,
  getById,
  deleteById,
};

function add(newCostCenter) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(newCostCenter),
  };

  return fetch(`${config.apiUrl}/cost-centers`, requestOptions)
    .then(handleResponse)
    .then((newTrip) => {
      return newTrip;
    });
}

function list(tripId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/cost-centers?filter=${JSON.stringify({
      where: { tripId },
      include: ["package", "trip", "travelType", "stayType"],
    })}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/cost-centers/${id}?filter=${JSON.stringify({
      include: ["trip", "package", "travelType", "stayType"],
    })}`,
    requestOptions
  ).then(handleResponse);
}

function update(updateCostCenter) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(updateCostCenter),
  };

  return fetch(
    `${config.apiUrl}/cost-centers/${updateCostCenter.id}`,
    requestOptions
  ).then(handleResponse);
}

function deleteById(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };

  return fetch(`${config.apiUrl}/cost-centers/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userActions.logout();
        // location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
